import React from "react"

import footerStyles from "../styles/footer.module.scss"

const Footer = () => (
  <footer>
    <div className={footerStyles.container}>
      <div className={footerStyles.content}>
        <div className={footerStyles.left}>
          <div className={footerStyles.socialMediaIcons}>
            <a href="https://www.facebook.com/taxartist">
              <img
                src={require("../images/facebook.svg")}
                alt="facebook link"
              />
            </a>
            <a href="https://www.linkedin.com/company/robert-dimarco-cpa-pa/">
              <img
                src={require("../images/linkedin.svg")}
                alt="linkedin link"
              />
            </a>
          </div>
          <a href="tel:7277875290">(727) 787-5290</a>
        </div>
        <div className={footerStyles.address}>
          <p>
            <span className={footerStyles.bold}>
              DiMarco &amp; Associates, CPAs
            </span>{" "}
          </p>
          <a href="https://goo.gl/maps/pT8pF1vmHajHdxw68">
            220 Pine Ave N., Suite A, Oldsmar, FL 34677
          </a>
        </div>
        <div className={footerStyles.right}>
          <p>© {new Date().getFullYear()} Tampa Technology Innovations</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
