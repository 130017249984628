import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import headerStyles from "../styles/header.module.scss"

const Header = () => {
  var [navExpanded, setNavExpanded] = useState(false)

  const navToggle = () => {
    setNavExpanded(!navExpanded)
  }

  return (
    <header>
      <div className={headerStyles.wrapper}>
        <h1>
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
            <img
              src={require("../images/logo.svg")}
              alt="dimarco &amp; associates logo"
            />
            DiMarco &amp; Associates, CPAs
          </Link>
        </h1>
        <nav>
          <button className={headerStyles.hideDesktop} onClick={navToggle}>
            <img src={require("../images/menu.svg")} alt="menu icon" />
          </button>
          <ul
            className={
              navExpanded ? headerStyles.showDesktop : headerStyles.hideMobile
            }
          >
            <li>
              <button
                className={headerStyles.hideDesktop}
                onClick={navToggle}
              >
                <img src={require("../images/exit.svg")} alt="exit icon" />
              </button>
            </li>
            <li>
              <Link to="/about">about</Link>
            </li>
            <li>
              <Link to="/services">services</Link>
            </li>
            <li>
              <Link to="/contact">contact</Link>
            </li>
            <li>
              <a href="/blog">blog</a>
            </li>
            <li>
              <a href="https://taxartist.smartvault.com/">client login</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
