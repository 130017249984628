import React from 'react';
import styled from 'styled-components';

const BackgroundImage = ({ image, color, height, backgroundPosition, title, children }) => {
    
    const Background = styled.div`
        background-image: url(${image});
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: ${height || '400px'};
        background-position: ${backgroundPosition || '50% 50%'};
        background-repeat: no-repeat;
        box-shadow: inset 0 0 0 1000px ${color};
        background-size: cover;
        
        @media (max-width: 875px) {
            min-height: 250px;
        }
    `

    const Title = styled.h1`        
        color: rgb(255, 255, 255, 80%);
        font-weight: 400;
        font-size: 2.75rem;
        white-space: nowrap;
    `
    
    return (
        <Background>
            {title ? <Title>{title}</Title> : children}
        </Background>
    )
}

export default BackgroundImage;